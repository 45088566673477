<template>
  <v-form v-model="valid">
    <v-row class="ml-2">
      <v-col>
        <v-select
          v-model="component.titleType"
          label="Tipo de Título"
          :items="component.titleTypeSelectItems"
          outlined
        >
        </v-select>
      </v-col>
      <v-col>
        <v-text-field
          v-model="component.date.date_initial"
          label="Data Inicial da Baixa"
          type="date"
          :rules="[(v) => !!v || 'Campo obrigatório para Buscar']"
          outlined
        ></v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          v-model="component.date.date_final"
          label="Data Final da Baixa"
          :rules="[(v) => !!v || 'Campo obrigatório para Buscar']"
          type="date"
          outlined
        >
        </v-text-field>
      </v-col>
      
      
      <div style="margin-top: 12px;">
        <v-btn
        class="mt-2"
        :disabled="!valid"
        color="success"
        rounded
        @click="component.cancelFind()"
        ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
      <v-col
      ><v-btn
      class="mt-2"
      :disabled="!valid"
      color="success"
      rounded
      @click="component.find()"
      ><v-icon>mdi-magnify</v-icon></v-btn
      ></v-col
      >
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    component: Object(),
  },

  data: () => ({
    valid: Boolean(),
    data: String(),
  }),
};
</script>

<style>
</style>