<template>
  <div>
    <v-data-table
      no-data-text="Sem registro!"
      :headers="header"
      :items="items"
      hide-default-footer
      :footer-props="{
        'items-per-page-options': [5, 10, 20, -1],
        'items-per-page-text': 'Items por página',
      }"
    >
      <template v-slot:item.down="{ item }">
        <v-simple-checkbox
          color="success"
          :ripple="false"
          v-model="item.down"
          @click="selectItem(item)"
        ></v-simple-checkbox>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      v-model="component.current"
      :length="component.total_table"
      color="success"
      :total-visible="10"
    ></v-pagination>
  </div>
</template>

<script>
export default {
  props: {
    component: Object(),
    items: Array,
    header: Array,
  },
  methods: {
    selectItem(val) {
      this.component.total = 0
      if(val.down == true) {
        this.component.selectedItems.push(val)
        for(let i = 0; i<this.component.selectedItems.length; i++) {
          this.component.total += this.component.selectedItems[i].title_balance
        }
      } else if(val.down == false) {
        for(let i = 0; i<this.component.selectedItems.length; i++) {
          if(val.id == this.component.selectedItems[i].id) {
            this.component.selectedItems.splice(i, 1)
          }
          this.component.total += this.component.selectedItems[i].title_balance
        }
      }
      this.component.total = this.component.total.toFixed(2)
    }
  }
};
</script>

<style>
</style>