<template>
  <v-row>
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title>Deseja baixar os títulos selecionados?</v-card-title>
        <v-card-subtitle
          >Os títulos selecionados serão baixados.</v-card-subtitle
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="
              () => {
                changeDialog();
              }
            "
            >Cancelar</v-btn
          >
          <v-btn
            color="success"
            @click="
              () => {
                submit();
              }
            "
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: Boolean(),
    submit: Function,
    changeDialog: Function,
  },
};
</script>

<style>
</style>